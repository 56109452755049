const getEnv = () => {
  // TODO, the window.teamsnapClientConfig.env is wrong in classic
  // need to stadardize the setting of the env variable
  if (window?.location?.host?.includes('staging')) {
    return 'staging';
  }
  if (window?.location?.host?.includes('localhost')) {
    return 'local';
  }
  return 'production';
};

export const ENVIRONMENT = getEnv();

export const API_URL = {
  local: 'http://localhost:5512',
  staging: 'https://staging-partner-api.teamsnap.com',
  production: 'https://partner-api.teamsnap.com',
}[ENVIRONMENT];

export const SENTRY_URL = 'https://eec51d1d3f29b0474c697ca6747c19b9@o126035.ingest.us.sentry.io/4506865390649344';
export const DATADOG_APP_ID = '';
export const DATADOG_CLIENT_TOKEN = '';
